<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Quotation
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <h3
            class="font-weight-700 custom-headline color-custom-blue"
            style="margin: 3.5px 0px"
          >
            Notes
          </h3>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-tabs
                v-model="quoteTypeTab"
                color="cyan"
                background-color="grey lighten-4"
              >
                <v-tab
                  class="font-size-16 font-weight-600 px-8 my-2"
                  href="#new"
                  style="width: 165px"
                >
                  New Door / Repair
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8 my-2"
                  href="#maintenance"
                  style="width: 168px"
                >
                  Maintenance
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="quoteTypeTab">
                <quotation-note-template
                  :quote-type="quoteTypeTab"
                  :key="`quotation-note-${quoteTypeTab}`"
                />
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Titles
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn v-on:click="createTitle" small class="mx-4">
                  <span class="font-size-16 font-weight-600">New Title</span>
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateTitleSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100">
                <tbody>
                  <tr v-for="(row, index) in titles" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="row.text"
                        dense
                        filled
                        label="Title"
                        solo
                        flat
                        :maxlength="50"
                        class="px-4 py-2"
                        hide-details
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click="deleteTitle(index)"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import QuotationNoteTemplate from "./QuotationNoteTemplate.vue";

export default {
  name: "quotation-notes-setting",
  data() {
    return {
      titles: [],
      pageLoading: true,
      quoteTypeTab: "new",
    };
  },
  components: {
    QuotationNoteTemplate,
  },
  methods: {
    createTitle() {
      this.titles.push({
        text: null,
      });
    },
    deleteTitle(index) {
      if (this.titles.length > 1) {
        this.titles.splice(index, 1);
      }
    },
    getTitleSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation-titles" })
        .then(({ data }) => {
          _this.titles = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTitleSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/quotation-titles",
          data: { titles: _this.titles },
        })
        .then(() => {
          _this.getTitleSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getTitleSetting();
  },
};
</script>
